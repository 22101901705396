import { useMemoizedContentGetter } from 'utils/contentful'
import { TypographyLongForm as Typography, MediaImageStandard } from 'components/Contentful'
import Section from 'components/Section'
import styles from './SectionFullBackground.module.scss'
import { Grid, Box, Link } from '@achieve/sunbeam'
import { BREAKPOINTS } from 'constants/viewport'
import classNames from 'classnames'
import { ArrowRight } from 'react-feather'
import { Eyebrow } from 'components/Eyebrow'
import { actualDevice, DEVICES } from 'utils/shared'
import useIsSsr from 'hooks/useIsSsr'
import { useState, useEffect } from 'react'
import { AchieveLink } from 'components/AchieveLink'
import useSsrViewportCheck from 'hooks/useSsrViewportCheck/useSsrViewportCheck'

function SectionFullBackground({ content }) {
  const isSsr = useIsSsr()
  const isMobile = useSsrViewportCheck(content?.isMobileUA, BREAKPOINTS.md)
  const [device, setDevice] = useState(null)

  useEffect(() => {
    if (!isSsr) {
      // check If current browser is a safari browser IOS
      setDevice(actualDevice(navigator.userAgent))
    }
  }, [isSsr])

  const {
    title,
    subTitle,
    background,
    link,
    button_google,
    button_apple,
    image_google,
    image_apple,
    eyebrow,
    backgroundConfigJson: { jsonContent },
  } = useMemoizedContentGetter(content, [
    'title',
    'subTitle',
    'background',
    'link',
    'eyebrow',
    'button_google',
    'button_apple',
    'image_google',
    'image_apple',
    'backgroundConfigJson',
  ])

  const backgroundObjectPosition = jsonContent?.backgroundObjectPosition ?? 'right'
  const backgroundObjectPositionMobile = jsonContent?.backgroundObjectPositionMobile ?? 'bottom'
  const titleNodeType = title?.textContent?.content[0]?.nodeType ?? 'heading-3'
  const titleVariant =
    titleNodeType == 'heading-2'
      ? isMobile
        ? 'displayM10'
        : 'displayL10'
      : isMobile
      ? 'displayM20'
      : 'displayM30'
  const mobilePadding = jsonContent?.mobilePadding ?? 'bottom'
  return (
    <Section
      className={styles['section']}
      data-testid="closer-fullbg-section"
      data-narrow={true}
      mobileMediaContent={background?.mobileMediaContent}
      middleMediaContent={background?.mobileMediaContent}
      mediaContent={background?.mediaContent}
      backgroundImageAlt={background?.mediaAltText}
      showPattern={jsonContent?.showPattern}
      backgroundObjectPosition={
        isMobile ? backgroundObjectPositionMobile : backgroundObjectPosition
      }
      variant={jsonContent?.background ?? 'bgDefault'}
    >
      <Box className={classNames(styles['fullbg-content'], styles[mobilePadding])} component="div">
        <Grid
          container
          direction={isMobile ? 'column' : 'row'}
          alignItems="center"
          className={
            styles[jsonContent?.longer ? 'outer-grid-container-long' : 'outer-grid-container']
          }
          data-testid="closer-fullbg-outer-grid-container"
        >
          {jsonContent?.textAlign == 'right' && <Grid item xs={4} md={6} lg={7}></Grid>}

          <Grid
            item
            xs={8}
            md={6}
            lg={5}
            className={styles['grid-item-text']}
            data-testid="closer-fullbg-grid-item-text"
          >
            <div
              className={classNames(
                styles['text-container'],
                ...[jsonContent?.textAlign == 'right' && styles['text-padding-left']]
              )}
              data-testid="closer-fullbg-text-container"
            >
              {eyebrow && (
                <Eyebrow
                  content={eyebrow?.textContent}
                  variant={isMobile ? 'displayS10' : 'displayS20'}
                  fontWeight="medium"
                  component="h1"
                  className={classNames(
                    styles['eyebrow'],
                    styles[`font-${eyebrow?.fontColor?.toLowerCase()}`]
                  )}
                />
              )}
              {jsonContent?.blur && <div className={styles['blur']}></div>}
              {title && (
                <Typography
                  content={title?.textContent}
                  variant={titleVariant}
                  fontWeight="bold"
                  className={classNames(
                    styles['title'],
                    styles[`font-${title?.fontColor?.toLowerCase()}`]
                  )}
                />
              )}
              {subTitle && (
                <Typography
                  content={subTitle?.textContent}
                  variant={isMobile ? 'displayXS30' : 'displayS10'}
                  className={classNames(
                    styles['sub-title'],
                    styles[`font-${subTitle?.fontColor?.toLowerCase()}`]
                  )}
                />
              )}
            </div>
            {link && (
              <Link underline="hover" fontWeight="bold" variant="displayS10" href={link?.linkHref}>
                <Typography
                  content={link?.linkText}
                  fontWeight="bold"
                  className={styles['text-link']}
                  variant="displayS10"
                />{' '}
                <ArrowRight className={styles['svg-link']} />
              </Link>
            )}
            {(button_google || button_apple) && (
              <Grid className={styles['buttons']} container>
                {(device === DEVICES.desktop || device === DEVICES.ios) && image_apple && (
                  <AchieveLink
                    href={button_apple?.linkHref}
                    data-testid="app-store-btn"
                    noLink
                    track={{
                      click_id: image_google?.mediaAltText || 'native-download-google-en',
                      click_text: `Achieve-Web | ${
                        image_google?.mediaAltText || 'native-download-google-en'
                      }`,
                      click_type: 'Button Click',
                      click_position: 1,
                      event_type: 'button_click',
                      nav_link_section: 'Closer Statement',
                      track_event: 'app_download_click',
                    }}
                  >
                    <div className={styles['button-apple']}>
                      <MediaImageStandard content={image_apple?.mediaContent} />
                    </div>
                  </AchieveLink>
                )}
                {(device === DEVICES.desktop || device === DEVICES.android) && image_google && (
                  <AchieveLink
                    href={button_google?.linkHref}
                    data-testid="google-play-btn"
                    noLink
                    track={{
                      click_id: image_apple?.mediaAltText || 'native-download-apple-en',
                      click_text: `Achieve-Web | ${
                        image_apple?.mediaAltText || 'native-download-apple-en'
                      }`,
                      click_type: 'Button Click',
                      click_position: 1,
                      event_type: 'button_click',
                      nav_link_section: 'Closer Statement',
                      track_event: 'app_download_click',
                    }}
                  >
                    <div className={styles['button-google']}>
                      <MediaImageStandard content={image_google?.mediaContent} />
                    </div>
                  </AchieveLink>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </Section>
  )
}
export { SectionFullBackground }
export default SectionFullBackground
