import styles from './HeroSection.module.scss'
import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import { LinkButton, Typography } from 'components/Contentful'
import { BREAKPOINTS } from 'constants/viewport'
import { Box } from '@achieve/sunbeam'
import { get as _get, debounce as _debounce } from 'lodash-es'
import { HeroMedia } from 'components/HeroMedia'
import { useEffect, useRef, useState } from 'react'
import useIsSsr from 'hooks/useIsSsr'
import { Button } from '@achieve/sunbeam'
import { cropText } from 'utils/shared'
import { useRouter } from 'next/router'
import useSsrViewportCheck from 'hooks/useSsrViewportCheck/useSsrViewportCheck'
import { normalizeCategoryName } from 'utils/conversions'

function HeroSection({ content }) {
  const isSsr = useIsSsr()
  const router = useRouter()

  const memoizedContent = useMemoizedContentGetter(content, [
    'title',
    'member',
    'message',
    'image',
    'video',
    'button',
    'video_xs_portrait',
    'video_xs_landscape',
    'video_xs_square',
    'link',
  ])
  const isMobile = useSsrViewportCheck(BREAKPOINTS.md, content?.isMobileUA, 'smaller')
  const isTablet = useSsrViewportCheck(BREAKPOINTS.lg, content?.isMobileUA, 'smaller')
  const [onViewport, setOnViewport] = useState(0)
  const isArticle = router.pathname === '/learn/[categoryname]'
  const isCategoryPage = isArticle || router.pathname === '/dollars-sense/[categoryname]'

  const {
    title,
    member,
    message,
    image,
    video,
    button,
    video_xs_portrait,
    video_xs_landscape,
    video_xs_square,
    link,
  } = memoizedContent

  const backgroundImage = _get(image, 'mediaContent', '')
  const backgroundImageMobile = _get(image, 'mobileMediaContent', '')
  const backgroundImageAlt = _get(image, 'mediaAltText', member?.textContent)
  const [backgroundVideo, setBackgroundVideo] = useState(null)
  const backgroundVideoFull = _get(video, 'source', null)
  const backgroundPortrait = _get(video_xs_portrait, 'source', null)
  const backgroundLandscape = _get(video_xs_landscape, 'source', null)
  const backgroundSquare = _get(video_xs_square, 'source', null)
  const thumbnailVideoFull = _get(video, 'thumbnail', null)
  const thumbnailPortrait = _get(video_xs_portrait, 'thumbnail', null)
  const thumbnailLandscape = _get(video_xs_landscape, 'thumbnail', null)
  const thumbnailSquare = _get(video_xs_square, 'thumbnail', null)
  const [thumbnail, setThumbnail] = useState(thumbnailVideoFull)
  const videoRef = useRef(null)

  useEffect(() => {
    const viewPortFn = () =>
      window.innerWidth === window.innerHeight ? 0 : window.innerWidth > window.innerHeight ? 1 : 2
    setOnViewport(viewPortFn())
    const handleResize = _debounce(() => setOnViewport(viewPortFn()), 250, { maxWait: 250 })
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isSsr])

  useEffect(() => {
    let video = null
    let thumbnail = null
    if (onViewport === 0) {
      video = backgroundSquare
      thumbnail = thumbnailSquare
    } else if (onViewport === 1) {
      video = backgroundLandscape
      thumbnail = thumbnailLandscape
    } else {
      video = backgroundPortrait
      thumbnail = thumbnailPortrait
    }
    setBackgroundVideo(video ?? backgroundVideoFull)
    setThumbnail(thumbnail ?? thumbnailVideoFull)
  }, [
    onViewport,
    backgroundVideoFull,
    backgroundSquare,
    backgroundLandscape,
    backgroundPortrait,
    thumbnailVideoFull,
    thumbnailSquare,
    thumbnailLandscape,
    thumbnailPortrait,
  ])

  const handleEvent = (e) => {
    const target = e.target
    const parent = target.parentElement
    //open video dialog
    if (target.dataset.videoDialogOpen || parent.dataset.videoDialogOpen) {
      videoRef.current.pause()
    }
    //close video dialog
    if (target.dataset.videoDialogClose || parent.dataset.videoDialogClose) {
      videoRef.current.play()
    }
  }

  return (
    <Section className={styles['hero-section']} contain={false}>
      <HeroMedia
        className={styles[`hero-section-media`]}
        image={isMobile ? backgroundImageMobile : backgroundImage}
        video={isTablet ? backgroundVideo : backgroundVideoFull}
        alt={backgroundImageAlt}
        videoRef={videoRef}
        thumbnail={thumbnail?.fields?.file?.url}
      >
        <Box className={styles['hero-section-info']}>
          <Box className={styles['hero-section-info-content']}>
            {title && (
              <Typography
                content={
                  isCategoryPage
                    ? `${normalizeCategoryName(router?.query?.categoryname)} ${
                        isArticle ? 'Articles' : 'Blog Posts'
                      }`
                    : title.textContent
                }
                fontWeight={isTablet ? 'bold' : 'medium'}
                variant={isTablet ? 'displayS11' : backgroundVideo ? 'displayS20' : 'displayS10'}
                component={isCategoryPage ? 'h1' : 'p'}
                data-testid="hero-section-title"
              />
            )}
            <Typography
              content={cropText(_get(member, 'textContent.content[0].content[0].value'), 30)}
              fontWeight="bold"
              variant={isTablet ? 'displayM10' : 'displayL10'}
              component={isCategoryPage ? 'h2' : 'h1'}
              data-testid="hero-section-header"
            />
            {message && (
              <Typography
                className={styles['hero-section-text-content']}
                content={message?.textContent}
                fontWeight={isTablet && backgroundImage ? 'medium' : 'regular'}
                variant={isTablet ? 'displayXS30' : backgroundVideo ? 'displayS10' : 'displayS20'}
                lineHeight="160%" // TODO alert and update Sunbeam
                data-testid="hero-section-content"
                onClick={handleEvent}
              />
            )}
            {button && (
              <Button color="neutral" variant="contained" href={button.linkHref}>
                <Typography
                  className={styles['button-text']}
                  content={button.linkText.content[0]}
                  variant="displayXS30"
                  fontWeight="bold"
                />
              </Button>
            )}
            {link && (
              <Box>
                <LinkButton
                  content={link}
                  typographicOptions={{
                    variant: 'displayS11',
                    fontWeight: 'bold',
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </HeroMedia>
    </Section>
  )
}
export { HeroSection }
export default HeroSection
