import Section from 'components/Section'
import styles from './EmployeeStoriesHero.module.scss'
import { get as _get } from 'lodash-es'
import { DATA_TEST_IDS } from 'constants/test-ids'
import { useMemoizedContentGetter } from 'utils/contentful'

import { Carousel } from 'components/Carousels'
import { MediaImageStandard, Typography } from 'components/Contentful'
import { AchieveTheme, Box, Grid } from '@achieve/sunbeam'
import { BREAKPOINTS } from 'constants/viewport'

import useViewportSize from 'hooks/useViewportSize'
import useSsrViewportCheck from 'hooks/useSsrViewportCheck/useSsrViewportCheck'

const FONT_MAP = {
  EYEBROW: {
    [BREAKPOINTS.xxs]: { variant: 'displayXS30', weight: 'bold' },
    [BREAKPOINTS.xs]: { variant: 'displayXS30', weight: 'bold' },
    [BREAKPOINTS.sm]: { variant: 'displayXS30', weight: 'bold' },
    [BREAKPOINTS.md]: { variant: 'displayS10', weight: 'bold' },
    [BREAKPOINTS.lg]: { variant: 'displayS10', weight: 'bold' },
    [BREAKPOINTS.xl]: { variant: 'displayS10', weight: 'bold' },
    [BREAKPOINTS.xxl]: { variant: 'displayS10', weight: 'bold' },
  },
  TITLE: {
    [BREAKPOINTS.xxs]: { variant: 'displayM10', weight: 'bold' },
    [BREAKPOINTS.xs]: { variant: 'displayM10', weight: 'bold' },
    [BREAKPOINTS.sm]: { variant: 'displayM10', weight: 'bold' },
    [BREAKPOINTS.md]: { variant: 'displayL10', weight: 'bold' },
    [BREAKPOINTS.lg]: { variant: 'displayL10', weight: 'bold' },
    [BREAKPOINTS.xl]: { variant: 'displayL10', weight: 'bold' },
    [BREAKPOINTS.xxl]: { variant: 'displayL10', weight: 'bold' },
  },
  COPY: {
    [BREAKPOINTS.xxs]: { variant: 'displayXS30', weight: 'regular' },
    [BREAKPOINTS.xs]: { variant: 'displayXS30', weight: 'regular' },
    [BREAKPOINTS.sm]: { variant: 'displayXS30', weight: 'regular' },
    [BREAKPOINTS.md]: { variant: 'displayS10', weight: 'regular' },
    [BREAKPOINTS.lg]: { variant: 'displayS10', weight: 'regular' },
    [BREAKPOINTS.xl]: { variant: 'displayS10', weight: 'regular' },
    [BREAKPOINTS.xxl]: { variant: 'displayS10', weight: 'regular' },
  },
}

const EmployeeStoriesHero = ({ content }) => {
  const {
    employeeStoriesHeroCopyA,
    employeeStoriesHeroCopyB,
    employeeStoriesHeroCopyC,
    employeeStoriesHeroEyebrow,
    employeeStoriesHeroImageA,
    employeeStoriesHeroImageB,
    employeeStoriesHeroImageC,
    employeeStoriesHeroTitleA,
    employeeStoriesHeroTitleB,
    employeeStoriesHeroTitleC,
  } = useMemoizedContentGetter(content, [
    'employeeStoriesHeroCopyA',
    'employeeStoriesHeroCopyB',
    'employeeStoriesHeroCopyC',
    'employeeStoriesHeroEyebrow',
    'employeeStoriesHeroImageA',
    'employeeStoriesHeroImageB',
    'employeeStoriesHeroImageC',
    'employeeStoriesHeroTitleA',
    'employeeStoriesHeroTitleB',
    'employeeStoriesHeroTitleC',
  ])

  const slides = [
    {
      sys: { id: 'item1' },
      eyebrow: employeeStoriesHeroEyebrow?.textContent,
      title: employeeStoriesHeroTitleA?.textContent,
      copy: employeeStoriesHeroCopyA?.textContent,
      background: _get(employeeStoriesHeroImageA, 'mediaContent', ''),
      alt: _get(employeeStoriesHeroImageA, 'mediaAltText', employeeStoriesHeroTitleA?.textContent),
      backgroundMobile: _get(employeeStoriesHeroImageA, 'mobileMediaContent', ''),
      priority: true,
      isMobileUA: content?.isMobileUA,
    },
    {
      sys: { id: 'item2' },
      eyebrow: employeeStoriesHeroEyebrow?.textContent,
      title: employeeStoriesHeroTitleB?.textContent,
      copy: employeeStoriesHeroCopyB?.textContent,
      background: _get(employeeStoriesHeroImageB, 'mediaContent', ''),
      alt: _get(employeeStoriesHeroImageB, 'mediaAltText', employeeStoriesHeroTitleB?.textContent),
      backgroundMobile: _get(employeeStoriesHeroImageB, 'mobileMediaContent', ''),
      isMobileUA: content?.isMobileUA,
    },
    {
      sys: { id: 'item3' },
      eyebrow: employeeStoriesHeroEyebrow?.textContent,
      title: employeeStoriesHeroTitleC?.textContent,
      copy: employeeStoriesHeroCopyC?.textContent,
      background: _get(employeeStoriesHeroImageC, 'mediaContent', ''),
      alt: _get(employeeStoriesHeroImageC, 'mediaAltText', employeeStoriesHeroTitleC?.textContent),
      backgroundMobile: _get(employeeStoriesHeroImageC, 'mobileMediaContent', ''),
      isMobileUA: content?.isMobileUA,
    },
  ]

  return (
    <Section childContainerClassName={styles['employee-stories-hero-container']}>
      <EmployeeStoriesCarousel slides={slides} testIDPrefix={DATA_TEST_IDS.EMPLOYEE_STORIES.HERO} />
    </Section>
  )
}

function Slide({ item = {}, selected }) {
  let { alt, background, backgroundMobile, copy, eyebrow, priority, sys, title, isMobileUA } = item
  const { breakpointLetterSize } = useViewportSize()
  const isSmall = useSsrViewportCheck(isMobileUA)
  const isExtraSmall = useSsrViewportCheck(isMobileUA, BREAKPOINTS.sm)

  const bgPosition = isExtraSmall ? 'top' : 'center'

  return (
    <Box className={styles['slide-outer']} data-item={sys.id}>
      <div className={styles['slide-inner']}>
        <MediaImageStandard
          content={isSmall ? backgroundMobile : background}
          alt={alt}
          layout="fill"
          objectFit="cover"
          objectPosition={bgPosition}
          priority={priority}
        />
        <Grid className={styles['slide-content-container']} container>
          <Grid className={styles['slide-text']} item xs={12} lg={6}>
            <Typography
              content={eyebrow}
              color={AchieveTheme?.sb?.colors?.primary?.achieveBlue}
              variant={FONT_MAP.EYEBROW[breakpointLetterSize].variant}
              fontWeight={FONT_MAP.EYEBROW[breakpointLetterSize].weight}
              component={selected ? 'h1' : 'p'}
            />
            <Typography
              content={title}
              variant={FONT_MAP.TITLE[breakpointLetterSize].variant}
              fontWeight={FONT_MAP.TITLE[breakpointLetterSize].weight}
              color={AchieveTheme?.sb?.colors?.neutral?.black}
              component="h2"
            />
            <Typography
              content={copy}
              variant={FONT_MAP.COPY[breakpointLetterSize].variant}
              fontWeight={FONT_MAP.COPY[breakpointLetterSize].weight}
              color={AchieveTheme?.sb?.colors?.neutral?.black}
              component="p"
            />
          </Grid>
        </Grid>
      </div>
    </Box>
  )
}

function EmployeeStoriesCarousel({ slides }) {
  return (
    <Carousel
      className={styles['employee-stories-carousel']}
      carouselItems={slides}
      itemComponent={Slide}
      variant="standard"
      idPrefix={`employee-stories-hero`}
      withIndicators
      classNameIndicators={styles['employee-stories-carousel-indicators']}
      classNameActiveDot={styles['employee-stories-carousel-active-dot']}
      classNameControls={styles['employee-stories-carousel-controls']}
      autoPlay
      infinityLoop
    />
  )
}

EmployeeStoriesHero.displayName = 'EmployeeStoriesHero'
export { EmployeeStoriesHero }
export default EmployeeStoriesHero
