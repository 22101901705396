import React, { useContext } from 'react'
import { pageSectionTypes } from './pageSectionTypes'
import { sectionContentTypes } from './sectionContentTypes'
import { ChildComponentGenerator } from './ChildComponentGenerator'
import { Grid } from '@achieve/sunbeam'
import { FeatureFlagContext } from 'providers/FeatureFlagProvider'
import { DEFAULT_EXPERIMENT, OFF_EXPERIMENT, UNASSIGNED_EXPERIMENT } from 'constants/experiments'
import useIsSsr from 'hooks/useIsSsr'

export function PageHandler({ pageContent, isMobileUA = true, isCrawler = false }) {
  const { featureFlags } = useContext(FeatureFlagContext)
  const isSsr = useIsSsr()
  return (pageContent?.fields?.sections || []).map((section) => {
    const experiment = featureFlags[section?.fields?.experiment?.fields?.featureFlagId]
    section.isMobileUA = isMobileUA
    const variation = section?.fields?.experimentVariation
    const element = (
      <ChildComponentGenerator
        contentTypes={pageSectionTypes}
        uiComponent={section?.fields?.uiComponent}
        isCrawler={isCrawler}
        {...section}
        key={section?.sys?.id}
      />
    )
    // For performance gains regarding cache we need to move all the section based experiments to the client
    // So on every single request We will be returning the control version no matter what and only on the client
    // according to the feature flag value are we going to hide or show the correct section
    // we need also to remove from the DOM the variation sections until it reach the client

    if (isSsr && (!variation || variation === DEFAULT_EXPERIMENT)) {
      return element
    }

    if (isSsr && variation !== DEFAULT_EXPERIMENT) {
      return null
    }

    if (
      !variation ||
      variation === experiment ||
      (variation === DEFAULT_EXPERIMENT &&
        [OFF_EXPERIMENT, UNASSIGNED_EXPERIMENT].includes(experiment))
    ) {
      return element
    }
  })
}

export function SectionHandler({ sectionContents }) {
  return (sectionContents || []).map((sectionContent) => {
    return (
      <ChildComponentGenerator
        contentTypes={sectionContentTypes}
        uiComponent={sectionContent.fields.uiComponent}
        {...sectionContent}
        key={sectionContent?.sys?.id}
      />
    )
  })
}

export function GridHandler({ gridContents, columns, ...gridProps }) {
  return (gridContents || []).map((gridContent, index) => {
    return (
      <Grid item xs={12 / columns} key={`${gridContent?.sys?.id}-${index}`} {...gridProps}>
        <ChildComponentGenerator
          contentTypes={sectionContentTypes}
          uiComponent={gridContent.fields.uiComponent}
          {...gridContent}
        />
      </Grid>
    )
  })
}
