import { useEffect, useState, useRef, useContext } from 'react'
import { AchieveTheme, Grid, Paper, Slider } from '@achieve/sunbeam'
import { Typography, MediaImageStandard, LinkButton } from 'components/Contentful'
import styles from './HeroForm.module.scss'
import { get as _get } from 'lodash-es'
import { PhoneDataContext } from 'providers/PhoneDataProvider'
import { AchieveLink } from 'components/AchieveLink'
import { Phone } from 'react-feather'
import { CtaCallButton } from 'components/CtaCallButton'

function SliderTool({
  stats,
  ctaText,
  ctaLinkBase,
  slider,
  isLarge,
  sliderStatsTitle,
  sliderQuestion,
  sliderQueryParam,
  title,
  hasPhone,
  phoneWrapper,
}) {
  const [sliderValue, setSliderValue] = useState(0)
  const [ctaHref, setCtaHref] = useState('')
  const dollarUSLocale = Intl.NumberFormat('en-US')
  const phoneData = useContext(PhoneDataContext)

  useEffect(() => {
    setCtaHref(`${ctaLinkBase}&${sliderQueryParam}=${sliderValue}`)
  }, [sliderValue, ctaLinkBase, sliderQueryParam])

  useEffect(() => {
    setSliderValue(slider.max / 2)
  }, [slider])

  function handleChange(e) {
    setSliderValue(e.target.value)
  }

  return (
    <Paper className={styles['slider-tool-paper']} data-testid="product-hero-slider-container">
      <Grid container>
        <Grid item xs={12}>
          <Typography
            content={sliderQuestion}
            variant={isLarge ? 'displayS10' : 'displayM10'}
            fontWeight="bold"
            className={styles['slider-tool-title']}
            data-testid="product-hero-slider-title"
          />
        </Grid>
        <Grid item xs={12} xl={7}>
          <div className={styles['slider-tool-wrapper']}>
            <Typography
              content={`$${dollarUSLocale.format(sliderValue)}${
                sliderValue === slider.max && slider.maxPlus ? '+' : ''
              }`}
              variant="displayS30"
              fontWeight="bold"
              className={styles['slider-tool-value']}
              data-testid="product-hero-dollar-value"
            />
            <Slider
              aria-label="dollar-amount-selection-slider"
              className={styles['slider-tool-slider']}
              classes={{
                mark: styles['marks'],
              }}
              data-testid="product-hero-slider-input"
              value={sliderValue}
              step={slider.step}
              min={slider.min}
              max={slider.max}
              marks={[
                {
                  value: slider.min,
                  label: (
                    <Typography
                      content={`$${dollarUSLocale.format(slider.min)}`}
                      variant="displayXS30"
                      fontWeight="medium"
                      className={styles['slider-tool-min-mark']}
                      data-testid="product-hero-slider-min"
                    />
                  ),
                },
                {
                  value: slider.max,
                  label: (
                    <Typography
                      content={`$${dollarUSLocale.format(slider.max)}${slider.maxPlus ? '+' : ''}`}
                      variant="displayXS30"
                      fontWeight="medium"
                      className={styles['slider-tool-max-mark']}
                      data-testid="product-hero-slider-max"
                    />
                  ),
                },
              ]}
              onChange={handleChange}
            />
          </div>
        </Grid>
        <Grid className={styles['cta-grid']} item xs={12} xl={5}>
          <div className={styles['slider-cta-container']}>
            <div className={styles['cta-call-container']}>
              <CtaCallButton phoneWrapper={phoneWrapper} variant="blue">
                <LinkButton
                  track={{
                    list_name: 'Hero Form',
                    click_id: ctaText,
                    hero_card_text: title,
                    form_ss_amount: sliderValue,
                  }}
                  content={{
                    linkHref: ctaHref,
                    linkText: ctaText,
                  }}
                  typographicOptions={{ variant: 'displayS10', fontWeight: 'bold' }}
                  color="primary"
                  variant="contained"
                  className={styles['slider-tool-cta']}
                  data-testid="product-hero-slider-cta"
                  fullWidth
                />
              </CtaCallButton>
            </div>
            {hasPhone && phoneData.phoneNumber && (
              <AchieveLink
                href={`tel:${phoneData.phoneNumber}`}
                noLink
                track={{
                  list_name: 'Product Hero Phone',
                  click_id: phoneData.phoneNumber,
                  click_text: `Achieve Web | ${phoneData.phoneNumber}`,
                }}
              >
                <div className={styles['phone-container']}>
                  <Phone
                    alt="phone button"
                    width={24}
                    height={24}
                    color={AchieveTheme?.sb?.colors?.primary?.achieveBlue}
                  />
                  <Typography
                    fontWeight="bold"
                    variant="displayS10"
                    className={styles['slider-phone']}
                    content={phoneData.phoneNumber}
                  />
                </div>
              </AchieveLink>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography
            content={sliderStatsTitle}
            variant="displayXS20"
            fontWeight="regular"
            className={styles['slider-tool-stats-title']}
            data-testid="product-hero-slider-stats-title"
          />
        </Grid>
        <Grid item xs={12}>
          {stats.map((st) => (
            <div className={styles['slider-tool-stats']} key={st.group}>
              <Typography
                content={st.figure}
                variant={isLarge ? 'displayXS30' : 'displayS10'}
                fontWeight="bold"
                data-testid="product-hero-slider-stat-value"
              />
              <Typography
                content={st.group}
                variant="displayXS20"
                fontWeight="regular"
                data-testid="product-hero-slider-stat-desc"
              />
            </div>
          ))}
        </Grid>
      </Grid>
    </Paper>
  )
}

function HeroForm({
  title,
  subtitle,
  breadcrumb,
  image,
  mobileImage,
  alt,
  sliderStatsTitle,
  sliderQuestion,
  sliderProps,
  hasPhone,
  phoneWrapper,
}) {
  const currentSection = useRef(null)
  const titleText = _get(title, 'content[0].content[0].value')

  return (
    <>
      <Grid
        container
        data-testid="desktop-product-hero-container"
        className={styles['hero-on-desktop']}
      >
        <Grid className={styles['product-hero-form-container']} item xxs={7}>
          <Typography
            content={breadcrumb}
            className={styles['breadcrumb-text']}
            data-testid="product-hero-breadcrumb-text"
            variant="displayS11CTA"
            fontWeight="medium"
          />
          <Typography
            content={title}
            variant="displayL10"
            data-testid="product-hero-title-text"
            fontWeight="bold"
            className={styles['title-text']}
          />
          <Typography content={subtitle} variant="displayS10" />
          <SliderTool
            {...sliderProps}
            isLarge
            sliderStatsTitle={sliderStatsTitle}
            sliderQuestion={sliderQuestion}
            title={titleText}
            hasPhone={hasPhone}
            phoneWrapper={phoneWrapper}
          />
        </Grid>
        <Grid item xxs={5} alignSelf="flex-start">
          <MediaImageStandard
            content={image}
            alt={alt}
            layout="responsive"
            objectFit="cover"
            objectPosition="center center"
            width="561"
            height="687"
            className={styles['desktop-hero-image']}
            data-testid="product-hero-image"
            priority={true}
          />
        </Grid>
      </Grid>
      <div className={styles['hero-on-mobile']}>
        <div
          className={styles['mobile-hero-image']}
          data-testid="mobile-product-hero-container"
          ref={currentSection}
        >
          <span className={styles['img-container']}>
            <MediaImageStandard
              content={mobileImage}
              layout="fill"
              objectFit="cover"
              objectPosition="top"
              priority={true}
            />
          </span>
          <div className={styles['mobile-hero-content']}>
            <Typography
              content={breadcrumb}
              className={styles['breadcrumb-text']}
              data-testid="product-hero-breadcrumb-text"
              variant="displayS10"
              fontWeight="bold"
            />
            <Typography
              content={title}
              variant="displayM10"
              fontWeight="bold"
              className={styles['title-text']}
              data-testid="product-hero-title-text"
            />
          </div>
        </div>
        <div className={styles['mobile-blue-section']}>
          <SliderTool
            {...sliderProps}
            className={styles['mobile-form-section']}
            isLarge={false}
            sliderStatsTitle={sliderStatsTitle}
            sliderQuestion={sliderQuestion}
            title={titleText}
            hasPhone={hasPhone}
            phoneWrapper={phoneWrapper}
          />
          <Typography
            content={subtitle}
            variant="displayM10"
            fontWeight="bold"
            className={styles['mobile-subtitle-text']}
            data-testid="product-hero-blue-text"
          />
        </div>
      </div>
    </>
  )
}

export { HeroForm }
